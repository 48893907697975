import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import './css/FileUploader.css';
import SignalRProgress from './SignalRProgress';


const FileUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [progressValue, setProgressValue] = useState(0);

    const [language, setLanguage] = useState('en');  // default to English

    const reset = () => {
        setSelectedFile(null);
        setDownloadUrl('');
        setErrorMessage('');
        setProgressValue(0);
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const handleFileChange = (event) => {
        reset(); // Reset everything when a new file is chosen
        setSelectedFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('language', language); // Add the language

        try {
            const response = await axios({
                method: 'post',
                url: 'Convert/PdfToMp3',
                data: formData,
                responseType: 'blob', // instructs axios to get the response as a Blob
            });

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'audio/mpeg' });
                const downloadUrl = window.URL.createObjectURL(blob);
                setDownloadUrl(downloadUrl);
                setErrorMessage('');
            } else {
                setErrorMessage('Conversion failed. Please try again.');
                setDownloadUrl(''); // Clear download URL in case of error
            }
        } catch (error) {
            console.log('Error details:', error); // Log the whole error object
            if (error.response) {
                if (error.response.data instanceof Blob && error.response.data.type === 'text/plain') {
                    const reader = new FileReader();
                    reader.onload = function () {
                        console.log('Error from the server:', this.result);
                    }
                    reader.readAsText(error.response.data);
                } else {
                    console.log(error.response.data);
                }
                console.log(error.response.status);
                console.log(error.response.headers);
            }
            setErrorMessage(error.message || 'Error occurred during conversion. Please try again later.');
            setDownloadUrl(''); // Clear download URL in case of error
        }
    };



    const handleDownload = () => {
        if (downloadUrl) {
            const a = document.createElement('a');
            a.href = downloadUrl;
            if (selectedFile) {
                const fileNameWithoutExtension = selectedFile.name.replace(/\.[^/.]+$/, "");
                a.download = `${fileNameWithoutExtension}.mp3`;
            } else {
                a.download = 'AudioBind.mp3';
            }
            a.click();
        }
    };

    
    const maxValue = 100;

    return (
        <div className="mt-3">
            <Form.Control className="modern-file-input" type="file" onChange={handleFileChange} style={{ width: '50vw' }} />
            
            <SignalRProgress progressValue={progressValue} setProgressValue={setProgressValue} /> 
            
            <Form.Label className="mt-2"></Form.Label>
            <Form.Select aria-label="Language" onChange={handleLanguageChange}>
                <option value="en">English</option>
                <option value="ru">Russian</option>
            </Form.Select>
            <br></br>
            <Button className="mt-2" variant="primary" onClick={handleFileUpload}>Upload and Convert</Button>
            {errorMessage && (
                <Alert variant="danger" className="mt-2">
                    {errorMessage}
                </Alert>
            )}
            {downloadUrl && !errorMessage && (
                <div className="mt-2">
                    <p style={{ color: 'white' }}>Conversion completed successfully!</p>
                    <Button variant="success" onClick={handleDownload}>Download MP3</Button>
                </div>
            )}
        </div>
    );
};

export default FileUploader;
