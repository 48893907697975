import React, { useState } from 'react';
import './css/Header.css';
import './css/TitleAnimation.css';
import logo from './assets/AudioBind_Logo.svg';
import { Navbar, Nav } from 'react-bootstrap';
import Login from './Login.js';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <Navbar bg="dark" variant="dark" expand="md" fixed="top" className="custom-navbar">
            <Navbar.Brand href="#">
                <img src={logo} alt="Logo" height="60" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-collapse" onClick={handleMenuToggle} />
            <Navbar.Collapse id="navbar-collapse" className={`justify-content-md-end ${isMenuOpen ? 'show' : ''}`}>
                <Nav className="align-items-center">
                    <Nav.Item>
                        <p className="text-white title-animation">PDF to MP3 Converter</p>
                    </Nav.Item>

                    {/*<Nav.Link href="#">Home</Nav.Link>
                        <Nav.Link href="#">About Us</Nav.Link>
                        <Nav.Link href="#">Video</Nav.Link>
                        <Nav.Link href="#">Audio</Nav.Link>
                        <Nav.Link href="#">Image</Nav.Link>
                        <Nav.Link href="#">Document</Nav.Link>
                        <Nav.Link href="#">Archive</Nav.Link>
                        <Nav.Link href="#">Device</Nav.Link>*/}
                </Nav>

                <Nav className="ml-md-2">
                    <Login />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
