import React from 'react';
import './css/ProgressBar.css';

const ProgressBar = ({ value, max }) => {
    let progress_bar = (value / max) * 100;

    // Ensure the progress doesn't exceed 100%
    progress_bar = Math.min(progress_bar, 100);

    return (
        <div className="progress-bar">
            <div className="progress" style={{ width: `${progress_bar}%` }}></div>
        </div>
    );
};

export default ProgressBar;
