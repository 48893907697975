import React, { useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import ProgressBar from './ProgressBar';

const SignalRProgress = () => {
    const [progressValue, setProgressValue] = useState(0);
    const [connection, setConnection] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl('/progresshub')
            .withAutomaticReconnect()
            .build();

        newConnection.start()
            .then(() => {
                console.log('Connection started');
                newConnection.on('ReceiveProgress', (progress) => {
                    console.log('Received progress:', progress);
                    setProgressValue(progress);
                });
            })
            .catch(e => console.log('Connection failed: ', e));

        setConnection(newConnection);
    }, []);

    return (
        <ProgressBar value={progressValue} max={100} />
    );
};

export default SignalRProgress;
