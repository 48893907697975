import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import FileUploader from './FileUploader.js';
import Header from './Header';
import { FetchData } from './FetchData';
import 'bootstrap/dist/css/bootstrap.min.css';


export class Layout extends Component {
  static displayName = Layout.name;

  render() {


    return (
      <div className="content">
           
            <Header />

            <FileUploader />
       
      </div>
    );
  }
}
