import React, { useState } from 'react';
import './css/Login.css';



const NavigationItem = () => {
    return (
        <li className="no-padding no-margin w-100 h-100 d-flex align-items-center justify-content-center">
            <div className="h-100 align-items-center p-header d-none d-md-inline-flex">
                <span data-user-button-login="" className="pr-4 font-weight-bold text-dark cursor-pointer">
                    <i className="fa fa-sign-in"></i> Log In
                </span>
            </div>
            <div className="h-100 align-items-center p-header-2 d-none d-md-inline-flex">
                <span data-user-button-register="" className="btn font-weight-bold btn-signup btn-lg w-auto py-2">
                    Sign Up
                </span>
            </div>
            <a
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                href="#"
                className="dropdown-toggle w-100 no-sm-border no-sm-border border-right nav-link bg-white d-xs-flex align-items-center h-100 d-flex d-sm-flex d-md-none d-lg-none pr-2 pr-sm-0"
            >
                <i className="fa fa-bars pr-2 fg-normal"></i> <span className="hidden-mobile text-normal">Menu</span>
            </a>
            <div className="pt-3 pb-3 pr-3 pl-3 dropdown-menu main-user-dropdown dropdown-menu-right dropdown-menu-hover w-100 border bg-white" style={{ zIndex: '-2', marginTop: '-2px' }}>
                <div className="h-100 align-items-center">
                    <span className="pr-4 font-weight-bold text-dark btn btn-block btn-light">Log In</span>
                </div>
                <div className="h-100 align-items-center">
                    <span className="btn font-weight-bold bg-success text-white btn-block">Sign Up</span>
                </div>
            </div>
        </li>
    );
};

export default NavigationItem;